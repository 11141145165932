import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { PageLoader } from "./components/page-loader";

import routes from "./router/router";

export const App: React.FC = () => {
	const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
	
	useEffect(() => {
		const fetchData = async () => {
		  if (isAuthenticated) {
				const accessToken = await getAccessTokenSilently({
					authorizationParams:{
						audience: 'https://medosphere-dev/',
					}
				});
				console.log(accessToken);
				localStorage.setItem("accessToken", accessToken);
		  }
		};
	
		fetchData();
  }, [isAuthenticated, getAccessTokenSilently]);

	if (isLoading) {
		return (
			<div className="page-layout">
				<PageLoader />
			</div>
		);
	}
	return (
		<Routes>
			{routes.map((route, index) => (
				<Route key={index} path={route.path} element={route.element} />
			))}
		</Routes>
	);
};